import React, { ReactNode } from "react";
import { Wrapper, Row, Col } from "../../components/Grid";
import styles from "./BuyPageLayout.module.scss";
import BlockContent from "../../components/BlockContent/BlockContent";
import classNames from "classnames";
import Text from "../../components/Text/Text";

interface BuyPageLayoutProps {
  title: string;
  description: string;
  filterRows?: ReactNode;
  children?: ReactNode;
  gradient: "polarBlue" | "duskRed";
}

const BuyPageLayout = ({
  title,
  description,
  filterRows,
  children,
  gradient
}: BuyPageLayoutProps) => {
  return (
    <div className={styles.root}>
      <div className={classNames(styles.header, styles[gradient])}>
        <Wrapper>
          <Row>
            <Col offsetMd={1} md={5} sm={6}>
              <Text variant="h1">{title}</Text>
            </Col>
          </Row>
          <Row className={filterRows ? undefined : styles.paddedDescription}>
            <Col offsetMd={1} md={5} sm={6}>
              <BlockContent
                className={styles.description}
                bodyVariant="default"
                content={description}
              />
            </Col>
          </Row>
          {filterRows}
        </Wrapper>
      </div>
      <Wrapper>{children}</Wrapper>
    </div>
  );
};

export default BuyPageLayout;
