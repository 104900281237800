import { WindowLocation } from "@reach/router";
import classNames from "classnames";
import { graphql } from "gatsby";
import React from "react";
import { Col, Row } from "../components/Grid";
import ProductCard from "../components/ProductCard/ProductCard";
import BuyPageLayout from "../layouts/BuyPageLayout/BuyPageLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { SanityProduct } from "../model/buy";
import { CommonContext, SanityPage, SanityRawContent } from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";
import styles from "./ProductsPage.module.scss";

export const pageQuery = graphql`
  query ProductListingPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityProductListingPage(_id: { eq: $_id }) {
      _id
      _type
      title
      language
      _rawDescription(resolveReferences: { maxDepth: 5 })
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      products {
        _id
        _type
        title
        subtitle
        image {
          ...SanitySimpleImage
          ...SanityHotspotCrop
        }
        slug {
          current
        }
      }
    }
  }
`;

const ProductListingPage = ({
  pageContext,
  data: { menus, page },
  location
}: ProductListingPageProps) => {
  const { title, _rawDescription: description, products } = page;
  return (
    <PageLayout
      menus={menus}
      siteArea={SiteArea.BUY}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      location={location}
      strings={pageContext.strings}
    >
      <BuyPageLayout title={title} description={description} gradient="duskRed">
        <Row className={classNames(styles.root, styles.cardRow)}>
          {products.map(product => (
            <Col
              key={product._id}
              xs={10}
              sm={6}
              md={4}
              className={styles.cardCol}
            >
              <ProductCard product={product} key={product._id} />
            </Col>
          ))}
        </Row>
      </BuyPageLayout>
    </PageLayout>
  );
};

export default ProductListingPage;

interface ProductListingPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityPage & {
      _rawDescription: SanityRawContent;
      products: SanityProduct[];
    };
    listing: { nodes: SanityProduct[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
